





































































import cloneDeep from 'lodash/cloneDeep';
import SelectServicesModal from '@/components/shared/SelectServicesModal.vue';
import ServiceAutocompleteInput from '@/components/shared/forms/ServiceAutocompleteInput.vue';
import BookingChoice from '@/components/bookings/BookingChoice.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { Service } from '@/model/service';
import { Location } from '@/model/location';
import { useServicesStore } from '@/store/services-store';
import { useCategoriesStore } from '@/store/categories-store';
import { Category } from '@/model/category';
import { useUsersStore } from '@/store/users-store';

export default Vue.extend({
  components: {
    SelectServicesModal,
    BookingChoice,
    ServiceAutocompleteInput
  },
  props: {
    publicBooking: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchTerms: '',
      childServices: [],
      categoryId: '',
      currentPage: 1,
      perPage: 10,
      clickedServicesIds: [] as number[]
    };
  },
  computed: {
    ...mapStores(
      useServicesStore,
      useUsersStore,
      useBookingsStore,
      useCategoriesStore
    ),
    categories(): Category[] {
      return this.categoriesStore.entities;
    },
    selectedServices(): Service[] {
      return this.bookingsStore.booking.services;
    },
    selectedLocation(): Location {
      return this.bookingsStore.booking.location;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    services(): Service[] {
      return this.servicesStore.getChosenLocationServices(
        this.bookingsStore.booking.location.id
      );
    },
    filteredCategories(): Category[] {
      return this.categories.filter(category =>
        this.servicesStore.entities
          .filter(
            service =>
              service.workplaces.length === 0 ||
              service.workplaces.some(
                workplace =>
                  workplace.location_id ===
                  this.bookingsStore.booking.location.id
              )
          )
          .some(service => service.category_id === category.id)
      );
    },
    rows(): number {
      return this.filteredServices.length;
    },
    pageCount(): number {
      return Math.ceil(this.rows / this.perPage);
    },
    filteredServices(): Service[] {
      let services = cloneDeep(this.services);
      if (this.categoryId) {
        services = services.filter(service => {
          return service.category_id === this.categoryId;
        });
      }
      if (this.searchTerms) {
        services = services.filter(service => {
          const splitVal = this.searchTerms.split(' ');
          const joinedSplitVal = splitVal.join('.*');
          const regex = new RegExp(joinedSplitVal, 'gi');
          return regex.test(`${service.no}${service.name}`);
        });
      }
      return services;
    },
    selectedServiceIds(): number[] {
      return this.selectedServices.map(sg => sg.id);
    }
  },
  methods: {
    toggleService(selected, service) {
      if (selected) {
        this.bookingsStore.addService(service);
        if (service.child_services && service.child_services.length > 0) {
          const childServices = cloneDeep(service.child_services);
          // On ne propose pas un child service s'il est déjà choisi
          this.childServices = childServices.filter(
            service => this.clickedServicesIds.indexOf(service.id) === -1
          );
          if (this.childServices.length > 0) {
            this.$bvModal.show('child-services-modal');
          }
        }
      } else {
        this.bookingsStore.removeService(service);
      }
      this.bookingsStore.resetAvailableBookings();
    },
    isServiceSelected(service) {
      return this.selectedServices.some(selectedService => {
        return selectedService.id === service.id;
      });
    },
    openServicesSelectionModal(categoryId) {
      this.categoryId = categoryId;
      this.$bvModal.show('select-services');
    },
    addServices(services) {
      this.clickedServicesIds = services.map(service => service.id);
      for (const service of services) {
        this.toggleService(true, service);
      }
    }
  }
});
