




















































import TextareaInput from '@/components/shared/forms/TextareaInput.vue';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import legalConditionsService from '@/services/legal-conditions.service';
import { MomentHelper } from '@/helpers/moment.helper';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { useUsersStore } from '@/store/users-store';
import { Location } from '@/model/location';
import { Booking } from '@/model/booking';
import { LegalCondition } from '@/model/legal-condition';
import { useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    TextareaInput,
    CheckboxInput
  },
  data() {
    return {
      legalConditions: [] as LegalCondition[],
      specialConditions: [] as LegalCondition[],
      generalTerms: null as LegalCondition | null,
      MomentHelper
    };
  },
  computed: {
    ...mapStores(useBookingsStore, useUsersStore, useUiStore),
    location(): Location {
      return this.bookingsStore.booking.location;
    },
    selectedBooking(): Booking | null {
      return this.bookingsStore.booking.selectedBooking;
    },
    vendorId(): number {
      return this.bookingsStore.booking.vendor.id;
    },
    isStaffOrMore(): boolean {
      return this.usersStore.isStaffOrMore;
    }
  },
  async created() {
    try {
      this.legalConditions = (await legalConditionsService.findAll(
        this.vendorId
      )) as LegalCondition[];
      this.legalConditions.forEach(legalCondition => {
        if (legalCondition.general) {
          this.generalTerms = legalCondition;
        } else {
          this.specialConditions.push(legalCondition);
        }
      });
      if (this.isStaffOrMore) {
        this.bookingsStore.setAcceptedLegalConditions(
          this.legalConditions.map(legalCondition => legalCondition.id)
        );
      }
    } catch (exception) {
      this.uiStore.alert(exception.message);
    }
  },
  methods: {
    setComment(comment) {
      this.bookingsStore.setComment(comment);
    },
    toggleLegalCondition(legalConditionId) {
      this.bookingsStore.toggleAcceptedLegalCondition(legalConditionId);
    }
  }
});
