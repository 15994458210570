import CustomerAutocompleteInput from '@/components/shared/forms/CustomerAutocompleteInput.vue';
import BookingStepPlace from '@/components/bookings/steps/BookingStepPlace.vue';
import BookingStepHeader from '@/components/bookings/BookingStepHeader.vue';
import BookingStepServices from '@/components/bookings/steps/BookingStepServices.vue';
import BookingStepDate from '@/components/bookings/steps/BookingStepDate.vue';
import BookingSummary from '@/components/bookings/BookingSummary.vue';
import BookingStepVerification from '@/components/bookings/steps/BookingStepVerification.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useBookingsStore } from '@/store/bookings-store';
import { Location } from '@/model/location';
import { Service } from '@/model/service';
import { Customer } from '@/model/customer';
import { Booking } from '@/model/booking';

export default Vue.extend({
  components: {
    CustomerAutocompleteInput,
    BookingStepPlace,
    BookingStepHeader,
    BookingStepServices,
    BookingStepDate,
    BookingSummary,
    BookingStepVerification,
    SubmitButton
  },
  computed: {
    ...mapStores(useLocationsStore, useBookingsStore),
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    customer(): Customer {
      return this.bookingsStore.booking.customer;
    },
    location(): Location {
      return this.bookingsStore.booking.location;
    },
    step(): number {
      return this.bookingsStore.booking.currentStep;
    },
    services(): Service[] {
      return this.bookingsStore.booking.services;
    },
    booking(): Booking | null {
      return this.bookingsStore.booking.selectedBooking;
    },
    saving(): boolean {
      return this.bookingsStore.booking.saving;
    }
  },
  methods: {
    onCreated(locationStep) {
      this.bookingsStore.$onAction(({ name, after }) => {
        after(() => {
          if (['nextStep', 'setStep', 'previousStep'].includes(name)) {
            if (this.step === locationStep && this.locations.length === 1) {
              this.bookingsStore.setLocation(this.locations[0]);
              this.bookingsStore.setStep(locationStep + 1);
            }
          }
        });
      });
    },
    selectCustomer(customer) {
      this.bookingsStore.selectCustomer(customer);
    },
    next() {
      this.bookingsStore.nextStep();
    },
    removeService(service) {
      this.bookingsStore.removeService(service);
    },
    servicesPrice(servicesGroup) {
      return servicesGroup.reduce((a, b) => a + b.price, 0);
    },
    servicesDuration(servicesGroup) {
      return servicesGroup.reduce((a, b) => a + b.duration, 0);
    }
  }
});
