

























import Vue from 'vue';

export default Vue.extend({
  props: {
    selectedBooking: {
      type: Object,
      default: () => {},
      required: true
    }
  }
});
