



















































































































































import moment from 'moment';
import intersectionBy from 'lodash/intersectionBy';
import flatten from 'lodash/flatten';
import Vue from 'vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import BookingStepDateAvailableBookings from '@/components/bookings/steps/BookingStepDateAvailableBookings.vue';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { Customer } from '@/model/customer';
import { Service } from '@/model/service';
import { Booking } from '@/model/booking';
import { useUsersStore } from '@/store/users-store';
import { useCustomersStore } from '@/store/customers-store';
import { InputOption } from '@/model/input-option';
import { Employee } from '@/model/employee';
import { useEmployeesStore } from '@/store/employees-store';
import { useUiStore } from '@/store/ui-store';
import bookingsService from '@/services/booking.service';
import * as Sentry from '@sentry/vue';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    SelectInput,
    DateInput,
    LoadingIndicator,
    BookingStepDateAvailableBookings,
    CheckboxInput,
    PlainButton
  },
  props: {
    fetchServicesHistory: {
      type: Boolean,
      required: true,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFetchingHistory: true,
      moment: moment
    };
  },
  computed: {
    ...mapStores(
      useBookingsStore,
      useUsersStore,
      useCustomersStore,
      useEmployeesStore,
      useUiStore
    ),
    customer(): Customer {
      return this.bookingsStore.booking.customer;
    },
    currentStep(): number {
      return this.bookingsStore.booking.currentStep;
    },
    selectedServices(): Service[] {
      return this.bookingsStore.booking.services;
    },
    selectedEmployeeId(): number | null {
      return this.bookingsStore.booking.favoriteEmployeeId;
    },
    favoriteEmployeeOnly(): boolean {
      return this.bookingsStore.booking.favoriteEmployeeOnly;
    },
    bookingInEdition(): Booking {
      return this.bookingsStore.booking.bookingInEdition;
    },
    date(): string | null {
      return this.bookingsStore.date;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    },
    isEdition(): boolean {
      return Object.keys(this.bookingInEdition).length > 0;
    },
    employeesOptions(): InputOption[] {
      return intersectionBy(
        flatten(this.selectedServices.map(service => service.employees)),
        'id'
      ).map(employee => {
        return {
          label: `${employee.firstname} ${employee.lastname}`,
          value: employee.id
        };
      });
    },
    selectedEmployee(): Employee | undefined {
      return this.selectedEmployeeId
        ? this.isPublic
          ? intersectionBy(
              flatten(this.selectedServices.map(service => service.employees)),
              'id'
            ).find((employee: Employee) => {
              return employee.id === this.selectedEmployeeId;
            })
          : this.employeesStore.getById(this.selectedEmployeeId)
        : undefined;
    }
  },
  async created() {
    await this.fetchServicesHistoryForCustomer();
    if (this.isEdition) {
      this.isFetchingHistory = true;
      this.selectedServices.forEach(selectedService => {
        const bookedService = this.bookingInEdition.events.find(
          bookingService => {
            return bookingService.service_id === selectedService.id;
          }
        );
        if (bookedService) {
          this.bookingsStore.setServiceDuration(
            selectedService,
            moment(bookedService.customer_end).diff(
              moment(bookedService.customer_start),
              'minutes'
            )
          );
        }
      });
      this.isFetchingHistory = false;
    }
  },
  methods: {
    setEmployeeId(employeeId?: number | string) {
      this.bookingsStore.setEmployeeId(employeeId);
      this.fetchServicesHistoryForCustomer();
    },
    setDate(date: string) {
      this.bookingsStore.setDate(date);
    },
    toggleFavoriteEmployeeOnly(employeeOnly: boolean, fetch: boolean) {
      this.bookingsStore.setEmployeeOnly(employeeOnly, fetch);
      this.fetchServicesHistoryForCustomer();
    },
    async fetchServicesHistoryForCustomer() {
      if (this.fetchServicesHistory) {
        this.isFetchingHistory = true;
        const variableDurationServices = this.selectedServices.filter(
          service => !service.fixed_duration
        );
        try {
          const servicesHistory = await Promise.all(
            variableDurationServices.map(service =>
              bookingsService.getServiceHistoryForCustomer(
                service.id,
                this.customer.id
              )
            )
          );
          for (let i = 0; i < variableDurationServices.length; i++) {
            this.bookingsStore.setServiceHistory(
              variableDurationServices[i],
              servicesHistory[i]
            );
          }
        } catch (e) {
          console.error(e.message);
          Sentry.captureException(e);
        }
      }
      this.isFetchingHistory = false;
    },
    incrementMinutes(service, minutes) {
      if (service.duration > 5 || minutes > 0) {
        this.bookingsStore.setServiceDuration(
          service,
          service.duration + minutes
        );
        this.bookingsStore.resetAvailableBookings();
        if (this.vendorId)
          this.bookingsStore.fetchTimeSlotsForCurrentWeekAndNextOne(
            this.vendorId
          );
      }
    }
  }
});
