




























import { MomentHelper } from '@/helpers/moment.helper';
import BookingChoice from '@/components/bookings/BookingChoice.vue';
import BookingSummaryServices from '@/components/bookings/BookingSummaryServices.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useBookingsStore } from '@/store/bookings-store';
import { Booking } from '@/model/booking';

export default Vue.extend({
  components: { BookingChoice, BookingSummaryServices },
  data() {
    return {
      bookings: [] as Booking[],
      MomentHelper
    };
  },
  computed: {
    ...mapStores(useLocationsStore, useBookingsStore)
  },
  methods: {
    getLocationById(id: number) {
      return this.locationsStore.getById(id);
    },
    selectBooking(selected, booking) {
      this.bookingsStore.selectBooking(booking);
      this.$bvModal.hide('booking-select-modal');
    }
  }
});
