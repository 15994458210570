


















































































































































































































































import CustomerAutocompleteInput from '@/components/shared/forms/CustomerAutocompleteInput.vue';
import BookingStepPlace from '@/components/bookings/steps/BookingStepPlace.vue';
import BookingStepHeader from '@/components/bookings/BookingStepHeader.vue';
import BookingStepServices from '@/components/bookings/steps/BookingStepServices.vue';
import BookingStepDate from '@/components/bookings/steps/BookingStepDate.vue';
import BookingSummary from '@/components/bookings/BookingSummary.vue';
import BookingStepVerification from '@/components/bookings/steps/BookingStepVerification.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import AddBookingMixin from '@/components/bookings/AddBookingMixin.ts';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useBookingsStore } from '@/store/bookings-store';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import PlainButton from '@/components/shared/PlainButton.vue';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AddBookingMixin>
>).extend({
  beforeRouteLeave(to, from, next) {
    if (from.name === 'EditBooking' && to.name === 'AddBooking') {
      this.resetBooking();
      next({ ...to, replace: true });
    }
    next();
  },
  components: {
    CustomerAutocompleteInput,
    BookingStepPlace,
    BookingStepHeader,
    BookingStepServices,
    BookingStepDate,
    BookingSummary,
    BookingStepVerification,
    SubmitButton,
    CheckboxInput,
    PlainButton,
    ButtonGroup
  },
  mixins: [AddBookingMixin],
  computed: {
    ...mapStores(useUsersStore, useBookingsStore),
    loggedInVendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    isEdition(): boolean {
      return (
        Object.keys(this.bookingsStore.booking.bookingInEdition).length > 0
      );
    },
    sendEmailConfirmation(): boolean {
      return this.bookingsStore.sendBookingConfirmation;
    }
  },
  created() {
    this.setVendor();
    this.setSendEmailConfirmation(true);

    if (this.$route.params.customer) {
      this.selectCustomer(this.$route.params.customer);
      if (this.step === 1) this.next();
    }
    this.onCreated(2);
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    setVendor() {
      if (this.loggedInVendorId)
        this.bookingsStore.selectVendorId(this.loggedInVendorId);
    },
    resetBooking() {
      this.bookingsStore.resetBookingAddForm();
      this.setVendor();
      if (this.$refs.customerAutocomplete)
        (this.$refs.customerAutocomplete as any).searchTerms = '';
    },
    setSendEmailConfirmation(value) {
      this.bookingsStore.setSendBookingConfirmation(value);
    },
    async save() {
      const savedSuccessfully = await this.bookingsStore.save();
      if (savedSuccessfully) {
        await this.$router.push({ name: 'BookingConfirmation' });
        window.scrollTo(0, 0);
      }
    }
  }
});
