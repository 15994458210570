














import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    stepIcon: {
      type: String,
      required: true
    },
    stepNumber: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapStores(useBookingsStore),
    currentStep(): number {
      return this.bookingsStore.booking.currentStep;
    }
  },
  methods: {
    setStep() {
      this.bookingsStore.setStep(this.stepNumber);
    }
  }
});
