


































































import moment from 'moment';
import { MomentHelper } from '@/helpers/moment.helper';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';

import Vue from 'vue';
import BookingSelectModal from '@/components/bookings/BookingSelectModal.vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { Booking } from '@/model/booking';
import { Service } from '@/model/service';
import {
  API_DATE_FORMAT,
  MAXIMUM_AVAILABLE_BOOKINGS_DAYS_DISPLAYED,
  MINIMUM_AVAILABLE_BOOKINGS_DAY_WIDTH
} from '@/constants';

interface AvailableBookingsForDate {
  date: string;
  bookings: Booking[];
}

export default Vue.extend({
  components: {
    EmptyDataset,
    LoadingIndicator,
    BookingSelectModal
  },
  data() {
    return {
      unsubscribeFromStore: null as null | (() => void),
      MomentHelper,
      elementWidth: 0,
      displayedDays: 1
    };
  },
  computed: {
    ...mapStores(useBookingsStore),
    errorFetchingTimeSlots(): boolean {
      return this.bookingsStore.availableBookings.failure;
    },
    selectedBooking(): Booking | null {
      return this.bookingsStore.booking.selectedBooking;
    },
    selectedBookingStart(): string | null {
      return this.bookingsStore.booking.selectedBookingStart;
    },
    selectedEmployeeId(): number | null {
      return this.bookingsStore.booking.favoriteEmployeeId;
    },
    services(): Service[] {
      return this.bookingsStore.booking.services;
    },
    bookingInEdition(): Booking {
      return this.bookingsStore.booking.bookingInEdition;
    },
    date(): string {
      return this.bookingsStore.date ?? '';
    },
    vendorId(): number {
      return this.bookingsStore.booking.vendor.id;
    },

    fetching(): boolean {
      const date = moment(this.date);
      while (
        date.isBefore(moment(this.date).add(this.displayedDays, 'days'), 'day')
      ) {
        if (!this.availableBookingsWereFetched(date.format(API_DATE_FORMAT))) {
          return false;
        }
        date.add(1, 'day');
      }

      return true;
    },
    noAvailability(): boolean {
      const date = moment(this.date);
      let appointmentsCount = 0;
      while (
        date.isBefore(moment(this.date).add(this.displayedDays, 'days'), 'day')
      ) {
        const dateAndBookings = this.filteredAvailableBookingsByDateAndEmployee.find(
          dateAndBookings =>
            dateAndBookings.date === date.format(API_DATE_FORMAT)
        );
        if (dateAndBookings) {
          appointmentsCount += dateAndBookings.bookings.length;
        }
        date.add(1, 'day');
      }

      return appointmentsCount === 0;
    },
    availableBookingsByDate(): AvailableBookingsForDate[] {
      const availableBookingsByDate: AvailableBookingsForDate[] = [];
      const date = moment(this.date);
      while (
        date.isBefore(moment(this.date).add(this.displayedDays, 'days'), 'day')
      ) {
        availableBookingsByDate.push({
          date: date.format(API_DATE_FORMAT),
          bookings: this.bookingsStore.getAvailableBookingsForDate(
            date.format(API_DATE_FORMAT)
          )
        });
        date.add(1, 'day');
      }

      return availableBookingsByDate;
    },
    filteredAvailableBookingsByDateAndEmployee(): AvailableBookingsForDate[] {
      return this.availableBookingsByDate.map(dateAndBookings => {
        return {
          ...dateAndBookings,
          bookings: dateAndBookings.bookings
            // Supprime les RDV qui commencent à la même heure (salle différente ou employé différent)
            .filter((availableBooking, index) => {
              if (dateAndBookings.bookings[index + 1]) {
                return (
                  availableBooking.start !==
                  dateAndBookings.bookings[index + 1].start
                );
              }
              return true;
            })
        };
      });
    }
  },
  watch: {
    elementWidth() {
      this.displayedDays = Math.min(
        Math.floor(this.elementWidth / MINIMUM_AVAILABLE_BOOKINGS_DAY_WIDTH),
        MAXIMUM_AVAILABLE_BOOKINGS_DAYS_DISPLAYED
      );
    }
  },
  created() {
    this.unsubscribeFromStore = this.bookingsStore.$onAction(
      ({ name, args, after }) => {
        if (name === '_setAvailableBookings') {
          after(() => {
            if (
              this.selectedBookingStart &&
              moment(this.selectedBookingStart).format(API_DATE_FORMAT) ===
                args[1]
            ) {
              this.onSelectBooking(true, this.selectedBookingStart);
            }
          });
        }
      }
    );
    this.fetchTimeSlotsForCurrentWeekAndNextOne();
  },
  destroyed() {
    if (this.unsubscribeFromStore) {
      this.unsubscribeFromStore();
    }
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    availableBookingsWereFetched(date: string): boolean {
      return this.bookingsStore.availableBookingsForDateWereFetched(date);
    },
    fetchTimeSlotsForCurrentWeekAndNextOne(force = false) {
      this.bookingsStore.fetchTimeSlotsForCurrentWeekAndNextOne(
        this.vendorId,
        force
      );
    },
    shouldBeSelected(booking) {
      return (
        this.selectedBooking && this.selectedBooking.start === booking.start
      );
    },
    onSelectBooking(selected, bookingStart) {
      if (selected) {
        const otherAvailableBookingsWithSameDate = (
          this.availableBookingsByDate ?? []
        ).find(
          dateAndBookings =>
            dateAndBookings.date ===
            moment(bookingStart).format(API_DATE_FORMAT)
        );
        let otherAvailableBookingsWithSameStart = [];
        if (otherAvailableBookingsWithSameDate) {
          otherAvailableBookingsWithSameStart = otherAvailableBookingsWithSameDate.bookings.filter(
            loopBooking => loopBooking.start === bookingStart
          );
        }
        if (otherAvailableBookingsWithSameStart.length > 1) {
          this.$bvModal.show('booking-select-modal');
          (this.$refs[
            'booking-select-modal'
          ] as any).bookings = otherAvailableBookingsWithSameStart;
        } else {
          this.bookingsStore.selectBooking(
            otherAvailableBookingsWithSameStart[0]
          );
        }
      } else {
        this.bookingsStore.selectBooking();
      }
    },
    handleWindowResize() {
      if (this.$refs.dateAvailableBookings)
        this.elementWidth = (this.$refs
          .dateAvailableBookings as Element).clientWidth;
    },
    previousDates() {
      this.bookingsStore.setDate(
        moment(this.date, API_DATE_FORMAT)
          .subtract(this.displayedDays, 'days')
          .format(API_DATE_FORMAT)
      );
    },
    nextDates() {
      this.bookingsStore.setDate(
        moment(this.date, API_DATE_FORMAT)
          .add(this.displayedDays, 'days')
          .format(API_DATE_FORMAT)
      );
    }
  }
});
