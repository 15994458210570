











































import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import BookingChoice from '../BookingChoice.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { useBookingsStore } from '@/store/bookings-store';
import { Location } from '@/model/location';
import { Service } from '@/model/service';

export default Vue.extend({
  components: {
    EmptyDataset,
    BookingChoice
  },
  data() {
    return {
      services: []
    };
  },
  computed: {
    ...mapStores(useLocationsStore, useBookingsStore),
    locations(): Location[] {
      return this.locationsStore.getOpenLocations;
    },
    selectedLocationId(): number {
      return this.bookingsStore.booking.location.id;
    },
    selectedServices(): Service[] {
      return this.bookingsStore.booking.services;
    }
  },
  methods: {
    setLocation(location?: Location) {
      this.bookingsStore.setLocation(location);
    },
    selectLocation(selected, location) {
      if (selected) {
        this.setLocation(location);
      } else {
        this.setLocation();
        this.services = [];
      }
    }
  }
});
