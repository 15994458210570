












































import FormInputMixin from '@/mixins/FormInputMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { Service } from '@/model/service';
import { BDropdown } from 'bootstrap-vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  mixins: [FormInputMixin],
  props: {
    rules: {
      type: String,
      default: ''
    },
    errors: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    selectedServiceIds: {
      type: Array,
      required: false,
      default: null
    },
    selectedLocation: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchTerms: null,
      services: [] as Service[],
      clearTimeoutId: null
    };
  },
  computed: {
    ...mapStores(useServicesStore),
    allServices(): Service[] {
      return this.servicesStore.entities;
    }
  },
  methods: {
    fetchServices(event) {
      if (event.key) {
        if (this.clearTimeoutId) {
          clearTimeout(this.clearTimeoutId);
        }
        this.clearTimeoutId = setTimeout(() => {
          if (this.searchTerms === '') {
            this.services = [];
            return;
          }
          this.services = this.allServices
            .filter(
              service =>
                !this.selectedLocation.id ||
                service.workplaces.length === 0 ||
                service.workplaces.some(
                  workplace =>
                    workplace.location_id === this.selectedLocation.id
                )
            )
            .filter(
              service =>
                !this.selectedServiceIds ||
                !this.selectedServiceIds.includes(service.id)
            )
            .filter(
              service =>
                service.no.toString().includes(this.searchTerms) ||
                service.name
                  .toUpperCase()
                  .includes(this.searchTerms.toUpperCase())
            );
          if (this.services.length > 0) {
            (this.$refs.dropdown as BDropdown).show();
          }
        }, 250);
      }
    },
    selectService(service) {
      this.searchTerms = '';
      this.$emit('onSelectService', service);
    }
  }
});
