




















import { MomentHelper } from '@/helpers/moment.helper';
import BookingSummaryServices from '@/components/bookings/BookingSummaryServices.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useBookingsStore } from '@/store/bookings-store';
import { Location } from '@/model/location';
import { Service } from '@/model/service';
import { Booking } from '@/model/booking';

export default Vue.extend({
  components: {
    BookingSummaryServices
  },
  data() {
    return {
      MomentHelper
    };
  },
  computed: {
    ...mapStores(useBookingsStore),
    location(): Location {
      return this.bookingsStore.booking.location;
    },
    selectedBooking(): Booking | null {
      return this.bookingsStore.booking.selectedBooking;
    },
    services(): Service[] {
      return this.bookingsStore.booking.services;
    }
  }
});
